<template>
  <div>
    <Loading v-if="loading"/>

    <div class="columns is-centered" v-if="!loading">
      <div class="column is-one-third">
        <div class="card">
          <div class="card-header has-background-link-light">
            <p class="card-header-title">
              Autentificare
            </p>
          </div>

          <div class="card-content">
            <div class="content">
              <b-field label="Email">
                <b-input type="email"
                         maxlength="30"
                         v-model="email">
                </b-input>
              </b-field>

              <b-field label="Password">
                <b-input type="password"
                         v-model="password"
                         password-reveal>
                </b-input>
              </b-field>

              <b-notification
                  type="is-danger is-light"
                  v-show="loginError"
                  aria-close-label="Close notification"
                  role="alert">
                Username/parola incorecte!
              </b-notification>
            </div>
          </div>

          <div class="card-footer p-3">
            <b-button type="is-primary" @click="login" :loading="loginInProgress" :active="!loginInProgress">
              Login
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../router'
import Loading from '../app/Loading'

export default {
  name: "Login",

  components: {
    Loading,
  },

  data() {
    return {
      password: '',
      email: '',
      loginInProgress: false,
      loginError: false,
      loading: true
    }
  },

  methods: {
    ...mapActions({
      loginAction: "auth/login"
    }),
    login() {
      this.loginInProgress = true;

      this.loginAction({ email: this.email, password: this.password }).then((success) => {
        this.loginInProgress = false;

        this.loginError = !success;

        success && router.push('/');
        success && this.$buefy.toast.open({
          message: 'Autentificare cu succes!',
          type: 'is-success'
        })
      })
    },
    resetError() {
      this.loginError = false;
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isAuthenticated'
    })
  },

  mounted() {
    if (this.isLoggedIn) {
      this.$router.push(this.$route.query.redirectFrom || "/");
    } else {
      this.loading = false;
    }
  },

  watch: {
    email: function () {
      this.resetError();
    },
    password: function () {
      this.resetError();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
